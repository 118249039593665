import styled from 'styled-components'

export const StyledPlayers = styled.div`
  min-height: 100vh;
  padding: 2.5rem 0 0 0;
  .players {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`
