import styled from 'styled-components'

export const StyledPlayerBox = styled.div`
  align-items: center;
  background-color: #161426;
  border: #cfa420 3px solid;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  font-weight: 700;
  min-height: 200px;
  justify-content: center;
  margin: 1rem;
  max-width: 200px;
  outline: #fff 2px solid;
  padding: 2.75rem 1.25rem;
  width: 100%;
  &:hover {
    background-color: #022f2a;
  }
`
